import * as React from "react";
import * as UI from "../components/UI";
import Helmet from "react-helmet";

const NotFoundPage = () => {
	return (
		<UI.Layout graficos="default" background="1">
			<Helmet>
				<title>Page not found!</title>
			</Helmet>
			<main>Page not found</main>
		</UI.Layout>
	);
};

export default NotFoundPage;
